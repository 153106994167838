/* You can add global styles to this file, and also import other style files */
@import '~flag-icons/css/flag-icons.min.css';

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");


@import './variables.scss';


:root{
  overflow-x:auto !important;
}

html, body {
  width:100%;
  min-width:100%;
 
  }

  .text-secondary-custom{
    color:$secondary-color
  }
  
.btn-custom-primary{
  background-color: $secondary-color;
  color:white;

  &:hover{
    background-color: $secondary-color !important;
    color:white !important;
  }
}

.btn-custom-secondary{
  background-color: $primary-color;
  color:white;

  &:hover{
    background-color: $primary-color !important;
    color:white !important;
  }
}

// .btn-secondary-bg{
//   background-color: $secondary-color;
//   color:white;

//   &:hover{
//     background-color: $secondary-color !important;
//     color:white !important;
//   }
// }

body{
background-color: #f6f7fb;
font-family: 'Open Sans', sans-serif !important;

font-weight: 400 !important;
}



.custom-tooltip{

  box-shadow: 0px 4px 16px rgba(0, 153, 112, 0.08);
   border-radius:7px;
 }
 .caret{
   position: absolute;
   right:-5%;
   top:3%;
 }
 
 .tooltip-bloc{
   position: absolute;
   top:5%;
   right:147%;
  
 }
 
.blur-image{
  filter: grayscale(100%);
 
}

.btn-secondary-custom{
  background-color:  #dfe5fa;
  &:hover{
    background-color:  #dfe5fae0 !important;
  }
}

#info-dialog{
  background-color: transparent !important;
  border: none !important;
  box-shadow: none;
}

.back{
  box-shadow: 0px 4px 16px rgba(0, 153, 112, 0.08);
  border-radius: 8px;
  border:none;
  background-color: white;
  &:hover{
    box-shadow: 0px 4px 16px rgba(0, 153, 112, 0.08) !important;
    border-radius: 8px !important;
    border:none;
    background-color: white !important;
  }
}

.list-status-access{
  transition-duration: .7s;
  ul{
      display: none;
      transition-duration: .7s;
      list-style: none;
      li{
        padding: 3.5%;
        color: black !important;
        &:hover{
          color: $secondary-color !important;
        }
      }
  }
  &:hover{
      color: black;
      transition-duration: .7s;
      input, label{
          color: $secondary-color;
          cursor: pointer;
      }
      ul{
          display: block;
      }
  }

  &.cliked{
    color: black;
    transition-duration: .7s;
    input, label{
        color: $secondary-color;
        cursor: pointer;
    }
    ul{
        display: block;
    }
  }

    &.uncliked{

    ul{
        display: none;
    }
  }
}


.btn-success-custom{
    background-color:$secondary-color;
    color:white;

    &:hover{
        background-color: $secondary-color !important;
        color:white !important;
    }
}

.primary-custom-bgcolor{
  background-color: #33424f;
}

.primary-custom-color{
  color: #33424f;
}




.table-shipping{

  background:linear-gradient(164.48deg, #501049 29.23%, #540F48 49.24%, #BC0632 89.01%, #FF0000 101.61%, rgba(54, 2, 68, 0) 102.19%);
  color: white;
}

.bg-danger-custom{
  background-color: $secondary-color;
  
  color: white;
}

::-webkit-scrollbar{
  width: 7px;
  height: 1px;
}
::-webkit-scrollbar-thumb{
  background-color:rgba(183, 178, 178, 0.742);
  border-radius: 5px;
}

.bg-custom-light-green-4{
  background-color: #8dc75b !important;
}
.bg-custom-light-red-4{
  background-color: #f1b895 !important;
}
.bg-custom-light-red-3{
  background-color: #f149a341 !important;
}
.bg-custom-light-green-3{
  background-color: #c1f18367 !important;
}

.card-shadow,.shadow-custom{
  background-color: #fff;
  box-shadow: 0px 4px 16px rgba(0, 153, 112, 0.08);
}



textarea:focus,

textarea.form-control:focus,
input.form-control:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
[type=text].form-control:focus,
[type=password].form-control:focus,
[type=email].form-control:focus,
[type=tel].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: 0 0 0 #d0e7f4;

}

select:focus,
select{
  border: 0;
  box-shadow: none;
  font-size: 14px !important;
}

input::placeholder, select::placeholder, textarea::placeholder, textarea{
    font-size: 12px !important;
    box-sizing: border-box !important;
}


label{
  font-size:15px !important;
  font-weight: 600;
}

.message-error{
  color:red;
}
input{

  box-sizing: border-box !important;
  border-radius: 5px !important;
}


@keyframes ldio-pqmmg4sfm9f {
  0% { transform: rotate(0) }
  100% { transform: rotate(360deg) }
}
.ldio-pqmmg4sfm9f div { box-sizing: border-box!important }
.ldio-pqmmg4sfm9f > div {
  position: absolute;
  width: 70px;
  height: 70px;
  top: 15px;
  left: 15px;
  border-radius: 50%;
  border: 6px solid #000;
  border-color: #48AAAC transparent #48AAAC transparent;
  animation: ldio-pqmmg4sfm9f 1.8518518518518516s linear infinite;
}

.ldio-pqmmg4sfm9f > div:nth-child(2), .ldio-pqmmg4sfm9f > div:nth-child(4) {
  width: 56px;
  height: 56px;
  top: 22px;
  left: 22px;
  animation: ldio-pqmmg4sfm9f 1.8518518518518516s linear infinite reverse;
}
.ldio-pqmmg4sfm9f > div:nth-child(2) {
  border-color: transparent #48AAAC transparent #48AAAC
}
.ldio-pqmmg4sfm9f > div:nth-child(3) { border-color: transparent }
.ldio-pqmmg4sfm9f > div:nth-child(3) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-pqmmg4sfm9f > div:nth-child(3) div:before, .ldio-pqmmg4sfm9f > div:nth-child(3) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  top: -6px;
  left: 26px;
  background: #48AAAC;
  border-radius: 50%;
  box-shadow: 0 64px 0 0 #48AAAC;
}
.ldio-pqmmg4sfm9f > div:nth-child(3) div:after {
  left: -6px;
  top: 26px;
  box-shadow: 64px 0 0 0 #48AAAC;
}

.ldio-pqmmg4sfm9f > div:nth-child(4) { border-color: transparent; }
.ldio-pqmmg4sfm9f > div:nth-child(4) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-pqmmg4sfm9f > div:nth-child(4) div:before, .ldio-pqmmg4sfm9f > div:nth-child(4) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  top: -6px;
  left: 19px;
  background: $secondary-color;
  border-radius: 50%;
  box-shadow: 0 50px 0 0 $secondary-color;
}
.ldio-pqmmg4sfm9f > div:nth-child(4) div:after {
  left: -6px;
  top: 19px;
  box-shadow: 50px 0 0 0 #48AAAC;
}
.loadingio-spinner-double-ring-3wbfv4ilhxv {
  width: 78px;
  height: 78px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
}
.ldio-pqmmg4sfm9f {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.78);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-pqmmg4sfm9f div { box-sizing: content-box; }

td,a,li,button,h6{
  font-size: 13px !important;
}
td{
  color: rgba(77, 75, 75, 0.988)!important;
}
th,h5,input,p,label,dt,dd{
  font-size: 12px !important;
}
th,label{
  color: #555 !important;
}
h3{
  font-size: 15px !important;
}

button{
  padding: .6rem !important;
  font-size: 13px !important;
}

